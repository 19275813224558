.footer {
    background-color: rgb(255, 255, 255); /* Fundo escuro */
    margin-top: 50px;
    color: rgb(0, 0, 0);
    border: 1px solid black;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: auto;
    margin: 0 auto;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
  }
  .footer a {
    color: rgb(15, 167, 238);
    font-weight: 600;
  }
  
  .br{
    width: 15px;
  }
  

  @media (max-width: 768px) {
    .footer {
       
        border: 1px solid rgba(0, 0, 0, 0);
    
      }
}