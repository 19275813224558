html {
    scroll-behavior: smooth;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.navbar-title {
    font-size: 24px;
    font-weight: bold;
}

.navbar-links {
    display: flex;
    gap: 20px;
    transition: all 0.3s ease;
}

.navbar-links a {
    text-decoration: none;
    color: #000;
    font-weight: 500;
    transition: color 0.3s;
}

.navbar-links a:hover {
    color: #007bff;
}

/* Estilos para o menu hamburger */
.menu-icon {
    display: none; /* Escondido em telas grandes */
    cursor: pointer;
}

.menu-icon .line {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 5px;
    transition: all 0.3s ease;
}

/* Para quando o menu é aberto */
.menu-icon.open .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open .line:nth-child(2) {
    opacity: 0;
}

.menu-icon.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-links.open {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.navbar-links.open a {
    display: block;
    padding: 10px 0;
    color: #000;
}

/* Media Queries */
@media (max-width: 768px) {
    .navbar-links {
        display: none;
        flex-direction: column;
    }

    .menu-icon {
        display: block; /* Exibe o menu em telas pequenas */
    }

    .navbar-title {
        font-size: 20px;
    }
}
